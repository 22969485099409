.badge {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle !important;
  border-radius: 0.358rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border 0s;
}

.badge-carrinho {
  display: flex;
  align-items: center;
  justify-content: center;
  right: -2px;
  top: -2px;
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle !important;
  border-radius: 100%;
  position: absolute;
  width: 23px;
  height: 23px;
  color: #fff;
  background-color: #e42728;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover,
a.badge:focus {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: var(--color-primary);
}
a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #4839eb;
}
a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(var(--color-primary-rgb), 0.5);
}
.badge-secondary {
  color: #fff;
  background-color: #82868b;
}
a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #696d71;
}
a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(var(--color-secondary-rgb), 0.5);
}
.badge-success {
  color: #fff;
  background-color: var(--color-success);
}
a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #1f9d57;
}
a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(var(--color-success-rgb), 0.5);
}
.badge-info {
  color: #fff;
  background-color: var(--color-info);
}
a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #00a1b5;
}
a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(var(--color-info-rgb), 0.5);
}
.badge-warning {
  color: #2a2e30;
  background-color: var(--color-warning);
}
a.badge-warning:hover,
a.badge-warning:focus {
  color: #2a2e30;
  background-color: #ff8510;
}
a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(var(--color-warning-rgb), 0.5);
}
.badge-danger {
  color: #fff;
  background-color: var(--color-danger);
}
a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #e42728;
}
a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(var(--color-danger-rgb), 0.5);
}
.bg-light {
  color: #2a2e30;
  background-color: #f6f6f6;
}
a.bg-light:hover,
a.bg-light:focus {
  color: #2a2e30;
  background-color: #ddd;
}
a.bg-light:focus,
a.bg-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(246, 246, 246, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #4b4b4b;
}
a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #323232;
}
a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(75, 75, 75, 0.5);
}

.badge {
  color: #fff;
}
.badge[class*='badge-'] [class*='icon-'] {
  line-height: 1;
}
.badge[class*='badge-'] a {
  color: #fff;
}
.badge[class*='badge-'] .dropdown-toggle span,
.badge[class*='badge-'] .dropdown-toggle i,
.badge[class*='badge-'] .dropdown-toggle svg,
.badge[class*='badge-'].dropdown-toggle span,
.badge[class*='badge-'].dropdown-toggle i,
.badge[class*='badge-'].dropdown-toggle svg {
  vertical-align: text-top;
}
.badge[class*='badge-'] .dropdown-toggle i,
.badge[class*='badge-'] .dropdown-toggle svg,
.badge[class*='badge-'].dropdown-toggle i,
.badge[class*='badge-'].dropdown-toggle svg {
  padding-left: 0.2rem;
}
.badge[class*='badge-'] .dropdown-toggle::after,
.badge[class*='badge-'].dropdown-toggle::after {
  position: relative;
  top: 0;
  left: 0;
  font-size: 1rem;
}
.badge[class*='badge-'] .dropdown-menu a {
  color: #6e6b7b;
}
.badge i,
.badge svg {
  height: 12px;
  width: 11px;
  font-size: 12px;
  stroke-width: 3;
  vertical-align: top;
}
.badge.badge-square {
  border-radius: 0;
}
.badge.badge-up {
  position: absolute;
  top: -11px;
  right: -9px;
  min-width: 1.429rem;
  min-height: 1.429rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.786rem;
  line-height: 0.786;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.badge.badge-up.badge-sm {
  top: -0.5rem;
  right: -0.5rem;
}
.badge-icon i,
.badge-icon svg {
  font-size: 100%;
  margin-right: 5px;
}
.bg-white.badge-glow,
.border-white.badge-glow,
.badge-white.badge-glow {
  box-shadow: 0px 0px 10px #fff;
}
.bg-black.badge-glow,
.border-black.badge-glow,
.badge-black.badge-glow {
  box-shadow: 0px 0px 10px #000;
}
.bg-dark.badge-glow,
.border-dark.badge-glow,
.badge-dark.badge-glow {
  box-shadow: 0px 0px 10px #4b4b4b;
}
.bg-light-dark {
  background-color: rgba(75, 75, 75, 0.12);
  color: #4b4b4b !important;
}
.bg-light.badge-glow,
.border-light.badge-glow,
.bg-light.badge-glow {
  box-shadow: 0px 0px 10px #f6f6f6;
}
.bg-danger.badge-glow,
.border-danger.badge-glow,
.badge-danger.badge-glow {
  box-shadow: 0px 0px 10px var(--color-danger);
}
.bg-light-danger {
  background: rgba(var(--color-danger-rgb), 0.12) !important;
  color: var(--color-danger) !important;
}
.bg-warning.badge-glow,
.border-warning.badge-glow,
.badge-warning.badge-glow {
  box-shadow: 0px 0px 10px var(--color-warning);
}
.bg-light-warning {
  background-color: rgba(var(--color-warning-rgb), 0.12);
  color: var(--color-warning) !important;
}
.bg-info.badge-glow,
.border-info.badge-glow,
.badge-info.badge-glow {
  box-shadow: 0px 0px 10px var(--color-info);
}
.bg-light-info {
  background-color: rgba(var(--color-info-rgb), 0.12);
  color: var(--color-info) !important;
}
.bg-success.badge-glow,
.border-success.badge-glow,
.badge-success.badge-glow {
  box-shadow: 0px 0px 10px var(--color-success);
}
.bg-light-success {
  background-color: rgba(var(--color-success-rgb), 0.12);
  color: #28c76f !important;
}
.bg-info.badge-glow,
.border-info.badge-glow,
.badge-info.badge-glow {
  box-shadow: 0px 0px 10px var(--color-info);
}
.bg-light-info {
  background-color: rgba(var(--color-info-rgb), 0.12);
  color: var(--color-info) !important;
}

.bg-secondary.badge-glow,
.border-secondary.badge-glow,
.badge-secondary.badge-glow {
  box-shadow: 0px 0px 10px #82868b;
}
.bg-light-secondary {
  background-color: rgba(var(--color-secondary-rgb), 0.12) !important;
  color: #82868b !important;
}
.bg-primary.badge-glow,
.border-primary.badge-glow,
.badge-primary.badge-glow {
  box-shadow: 0px 0px 10px var(--color-primary);
}
.bg-light-primary {
  background-color: rgba(var(--color-primary-rgb), 0.12);
  color: var(--color-primary) !important;
}
