.pro-sidebar {
  height: 100vh !important;
  box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-menu-item.active
  .pro-inner-item:before {
  width: 10px !important;
  min-width: 10px !important;
  height: 10px !important;
  margin-right: 25px;
  border: 1.5px solid #fff !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-menu-item:not(.active)
  .pro-inner-item:before {
  width: 10px !important;
  min-width: 10px !important;
  height: 10px !important;
  margin-right: 25px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover,
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  color: #fff !important;
}

.pro-sidebar .pro-menu .pro-menu-item {
  padding: 0 10px;
  border-radius: var(--border-radius);

  &.active {
    color: #fff;
    background: linear-gradient(
      118deg,
      var(--color-primary),
      rgba(var(--color-primary-rgb), 0.7)
    );

    box-shadow: 0 2px 10px 0.5px rgba(0, 0, 0, 0.3);
  }
}

.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper,
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background-color: transparent !important;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pro-menu {
  padding: 10px 10px;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pro-menu-item.pro-sub-menu.has-active .pro-inner-item {
  background-color: rgba(var(--theme-color-rgb), 0.04);
  border-radius: var(--border-radius);
}


.pro-sidebar.collapsed {
  .xcoding-text {
    display: flex;
  }

  .xcoding {
    display: none !important;
  }
}

.pro-sidebar.collapsed ~ .dashboard .navbar-container {
  width: calc(100% - 130px);
}

.pro-sidebar:not(.collapsed) ~ .dashboard .navbar-container {
  width: calc(100% - 320px);
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu:hover
  > .pro-inner-list-item {
  margin-left: 10px !important;
  margin-top: 27px !important;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  background-color: #fff !important;
  box-shadow: 20px 3px 24px 0 rgba(34, 41, 47, 0.1);
}

.pro-sidebar:not(.collapsed) {
  .xcoding-text {
    display: none;
  }
}

.pro-sidebar {
  top: 0;
}

.pro-sidebar .pro-menu .pro-menu-item.active a {
  color: #fff !important;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
  color: #fff !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0px !important;
}

.li.pro-menu-item.pro-sub-menu.has-active.open:not(.active) {
  padding: 6px 0px 6px 4px !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 10px 13px !important;
  margin-top: 3px;
}

.pro-sidebar .pro-menu .pro-menu-item:not(.pro-sub-menu) > .pro-inner-item {
  padding: 5px 35px 5px 0px !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item {
  padding: 5px 35px 5px 10px !important;
}

li.pro-menu-item.pro-sub-menu {
  padding: 0 !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  background-color: transparent !important;
}
