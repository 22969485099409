@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import './animation.scss';
@import './badge.scss';
@import './avatar.scss';
@import './pro-sidebar';

:root {
  --color-primary-rgb: 79, 134, 246;
  --color-secondary-rgb: 130, 134, 139;
  --color-danger-rgb: 234, 84, 85;
  --color-success-rgb: 40, 199, 111;
  --color-info-rgb: 0, 207, 232;
  --color-warning-rgb: 255, 159, 67;

  --color-primary: rgb(79, 134, 246);

  --color-secondary: #82868b;
  --color-secondary-lighten: #f2f2f2;

  --color-success: #35ff69;
  --color-success-lighten: #e5f8ed;

  --color-info: #00cfe8;
  --color-info-lighten: #e0f9fc;

  --color-danger: #ea5455;
  --color-danger-lighten: #fceaea;

  --color-warning: #ff9f43;
  --color-warning-lighten: #fff3e8;

  --color-gray0: #f8f8f8;
  --color-gray1: #ededed;
  --color-gray2: #dae1e7;
  --color-gray3: #babfc7;
  --color-gray4: #b8b8b8;
  --color-gray5: #a3a3a3;
  --color-gray6: #8f8f8f;
  --color-gray7: #6d696a;
  --color-gray8: #535051;
  --color-gray9: #2a2829;
  --border-radius: 0.4rem;

  --theme-color-rgb: 255, 255, 255;
  --body-color: #ffffff;
  --body-bg: #302e34;
  --background-secondary: #302e34;
  // --body-bg: #888098;
  --txt-muted: #999999;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: #2b2b2b;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #121212;
  width: 5px;
}

* {
  box-sizing: border-box;
  outline: none !important;
}

// hr.bold {
//   border-top: 3px solid rgba(0, 0, 0, 0.05) !important;
// }
// hr:not(.bold) {
//   border-top: 2px solid rgba(0, 0, 0, 0.05) !important;
// }

html {
  font-size: 14px;
  letter-spacing: 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
main {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 80px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: var(--color-info);
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;

  /* TABS */

  h2.inactive {
    color: #cccccc;
  }

  h2.active {
    color: #fff;
    border-bottom: 2px solid var(--color-primary);
  }
  input[type='button'],
  input[type='submit'],
  input[type='reset'] {
    background-color: #538dff;
    border: none;
    color: white;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
    box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  input[type='button']:hover,
  input[type='submit']:hover,
  input[type='reset']:hover {
    background-color: #fff;
    color: var(--color-info);
  }

  input[type='button']:active,
  input[type='submit']:active,
  input[type='reset']:active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }

  input[type='text'] {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    width: 85%;
    border: 2px solid #f6f6f6;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
  }

  input[type='text']:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
  }

  input[type='text']:placeholder {
    color: #fff;
  }

  .underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: var(--color-primary);
    content: '';
    transition: width 0.2s;
  }

  .underlineHover:hover {
    color: #fff;
  }

  .underlineHover:hover:after {
    width: 100%;
  }
}

body {
  margin: 0;
  padding-right: 0 !important;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: 400;
  background-color: var(--body-bg);
  color: var(--body-color) !important;

  code {
    color: #ff5a9c;
  }

  .bg-gray,
  .bg-gray1 {
    background-color: #1e1d22;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  .card {
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.28);
  }

  .form-control {
    &::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.2) !important;
    }
    &:-moz-placeholder {
      color: rgba(255, 255, 255, 0.2) !important;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: rgba(255, 255, 255, 0.2) !important;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.2) !important;
    }
    &::-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.2) !important;
    }

    &::placeholder {
      transition: all 0.2s ease;
      color: rgba(255, 255, 255, 0.2) !important;
    }
  }

  .pro-sidebar {
    color: #b4b7bd;
  }
  .txt-muted {
    color: #999999 !important;
  }
  table {
    color: #b4b7bd !important;
  }
  label,
  .input-group-text,
  .custom.input-group select {
    color: #ffffff;
  }
  .custom.input-group select option {
    background-color: var(--body-bg);
  }
  input {
    color: #b4b7bd;
  }
  .pro-sidebar .pro-menu .pro-menu-item:not(.active) a {
    color: #b4b7bd;
  }
  .modal-content {
    background-color: #1e1d22;
  }
  .navbar-container,
  .card {
    background: var(--body-bg) !important;
  }

  thead {
    background-color: #1c1b20;
  }

  .pro-sidebar > .pro-sidebar-inner {
    background: var(--body-bg);
  }

  .dashboard .navbar-container-bg {
    background: linear-gradient(
      180deg,
      rgba(30, 29, 34, 0.95) 44%,
      rgba(30, 29, 34, 0.46) 73%,
      rgba(30, 29, 34, 0)
    );
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  #swal2-html-container {
    color: #ffffff;
  }

  .btn-close {
    box-shadow: 0 5px 20px 0 rgba(130, 130, 130, 0.1);

    background: var(--body-bg)
      url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='gray'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E")
      50%/0.75rem auto no-repeat !important;
  }
}

.offcanvas {
  background-color: var(--body-bg) !important;

  .btn-close {
    transform: translate(3px, -5px) !important;
  }

  .offcanvas-header {
    background: rgba(var(--theme-color-rgb), 0.025);
  }
}

.bg-white-theme {
  background-color: var(--body-bg);
}

#formFooter {
  background-color: var(--color-info);
  border-top: 1px solid #fff;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

#icon {
  margin-top: 15px;
  width: 60%;
}

.justify-center {
  display: flex;
  justify-content: center;
}
.justify-between {
  display: flex;
  justify-content: space-between !important;
}
.between {
  justify-content: space-between !important;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-start {
  display: flex;
  justify-content: start !important;
}
.text-end {
  text-align: end;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify !important;
}
.font-medium {
  font-weight: 500 !important;
}
.bold {
  font-weight: bold !important;
}
.bolder {
  font-weight: bolder !important;
}

// footer {
//   position: fixed;
//   left: 0;
//   bottom: 5px;
//   width: 100%;
//   color: white;
//   text-align: center;
// }

.pointer {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}

.vwm-100 {
  max-width: 100vw;
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}

.p-5 {
  padding: 4rem !important;
}

.pl-5,
.px-5 {
  padding-left: 4rem !important;
}

.pr-5,
.px-5 {
  padding-right: 4rem !important;
}

.pl-3 {
  padding-left: 2rem !important;
}

.pr-3 {
  padding-right: 2rem !important;
}

.ml-3 {
  margin-left: 2rem !important;
}

.mr-3 {
  margin-right: 2rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.d-none {
  display: none !important;
}

.img-fluid,
.img-thumbnail {
  max-width: 100%;
  height: auto;
}

textarea.form-control {
  min-height: 7rem !important;
}

.form-control:not(textarea) {
  height: 2.714rem !important;
}

.form-control {
  display: block !important;
  width: 100% !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.45 !important;
  color: #bebebe !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  padding: 0.438rem 1rem;
  background-color: transparent !important;
  background-clip: padding-box !important;
  border: 1px solid !important;
  border-color: rgba(var(--theme-color-rgb), 0.1) !important;
  border-radius: var(--border-radius) !important;

  &.disabled,
  &:disabled {
    background-color: rgba(var(--theme-color-rgb), 0.03) !important;
  }

  &:focus {
    box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
    border-color: var(--color-primary) !important;
  }

  &:hover:not(:focus) {
    border-color: rgba(var(--theme-color-rgb), 0.15) !important;
  }

  &.error {
    border-color: var(--color-danger) !important;
  }
}

.form-control-group {
  width: 100%;
  height: 2.6rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: var(--body-color) !important;
  padding: 0.438rem 1rem;
  background-color: transparent;
  background-clip: padding-box;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.input-group {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid;
  border-color: rgba(var(--theme-color-rgb), 0.1) !important;
  border-radius: var(--border-radius) !important;
  &:hover {
    border-color: rgba(var(--theme-color-rgb), 0.15) !important;
  }
}
.input-group.error {
  border-color: var(--color-danger);
}

.input-group:focus-within {
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
  border-color: var(--color-primary) !important;
}

.input-group .input-group-prepend {
  margin-left: 10px;
}
.input-group .input-group-append {
  margin-right: 10px;
}

@media (min-width: 992px) {
  .d-lg-flex {
    display: flex !important;
  }
  .list-item {
    min-width: 260px;
    width: calc((100% - 10px) / 5 - 10px);
    max-height: 600px;
    min-height: 460px;
    margin-top: 20px;
  }
}
@media (max-width: 991px) {
  .list-item {
    min-width: 85vw;
    max-width: 85vw;
    height: calc(100vh - 100px);
  }
}
.pro-sidebar img {
  height: 50px;
  transition: all 0.3s;
}
.pro-sidebar.collapsed img {
  height: 35px;
}
@media (max-width: 768px) {
  .pro-sidebar.collapsed:not(.toggled) {
    left: -270px !important;
  }
  .pro-sidebar.collapsed {
    width: 270px !important;
    min-width: 270px !important;
  }

  .md-none {
    display: none !important;
  }
  .dashboard .navbar-container {
    width: calc(100%) !important;
    right: 0px !important;
    margin-top: 0 !important;
    border-radius: 0 !important;
  }
  .dashboard main {
    margin: 80px 1.1rem 0px !important;
  }
  .d-sm-grid {
    display: grid;
  }
  table.rwd-table {
    border: 0;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      display: block;
      margin-bottom: 1.025em;
      box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.12);
      border-radius: 4px;

      &:hover {
        box-shadow: 0px 5px 11px #0000001f !important;
      }
    }

    td {
      border-top: none !important;
      border-bottom: 1px solid #eee;
      display: block;
      font-size: 0.9em;
      text-align: right !important;
      font-weight: 500;
      min-height: 37px;
      padding-top: 17px;
      padding-left: 1rem !important;
      padding-right: 1rem !important;

      .avatar-group {
        justify-content: flex-end;
      }

      &::before {
        /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
        content: attr(data-label);
        float: left !important;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.8em;
        margin-top: -15px;
        margin-right: 5px;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    .justify-between-sm {
      display: flex;
      justify-content: space-between;
    }

    .acao {
      border-left: none !important;
    }

    [class*='w-'] {
      width: 100% !important;
    }
  }
  .rwd-table .btn-group {
    display: flex !important;
  }
  .rwd-table tr td {
    word-break: break-word;
  }
  .justify-content-sm-between {
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 768px) {
  .dashboard .navbar-container {
    width: calc(100%) !important;
  }
  .d-lg-none {
    display: block !important;
  }
  .md-none {
    display: none !important;
  }
}
@media (min-width: 769px) {
  .float-md-right {
    float: right;
  }
  .d-lg-flex {
    display: flex !important;
  }
}

.brand-logo {
  filter: drop-shadow(0 0 6px black);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #fff;
}

label {
  font-size: 0.9rem;
  margin-bottom: 0.2857rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.ml-3 {
  margin-left: 0.5rem;
}

.inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

#root {
  width: 100%;
}

.btn-block {
  width: 100%;
}

.btn {
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.786rem 1.5rem;
  border-radius: var(--border-radius) !important;
  vertical-align: middle;
  user-select: none;
  font-size: 1rem;
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border 0s;
}

.btn-primary {
  border-color: var(--color-primary) !important;
  background-color: var(--color-primary) !important;
}
.btn-primary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 6px 20px -10px var(--color-primary);
}
.btn-secondary {
  border-color: var(--color-secondary) !important;
  background-color: var(--color-secondary) !important;
}
.btn-secondary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 6px 20px -10px var(--color-secondary);
}
.btn-success {
  border-color: var(--color-success) !important;
  background-color: var(--color-success) !important;
}
.btn-success:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 6px 20px -10px var(--color-success);
}
.btn-danger {
  border-color: var(--color-danger) !important;
  background-color: var(--color-danger) !important;
}
.btn-danger:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 6px 20px -10px var(--color-danger);
}
.btn-info {
  border-color: var(--color-info) !important;
  background-color: var(--color-info) !important;
}
.btn-info:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 6px 20px -10px var(--color-info);
}
.btn-warning {
  border-color: var(--color-warning) !important;
  background-color: var(--color-warning) !important;
}
.btn-warning:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 6px 20px -10px var(--color-warning);
}
.btn-light-primary {
  background-color: rgba(var(--color-primary-rgb), 0.12) !important;
  color: var(--color-primary) !important;
}
.btn-light-danger {
  background-color: rgba(var(--color-danger-rgb), 0.12) !important;
  color: var(--color-danger) !important;
}
.btn-light-info {
  background-color: rgba(var(--color-info-rgb), 0.12) !important;
  color: var(--color-info) !important;
}
.btn-light-warning {
  background-color: rgba(var(--color-warning-rgb), 0.12) !important;
  color: var(--color-warning) !important;
}
.btn-light-success {
  background-color: rgba(var(--color-success-rgb), 0.12) !important;
  color: var(--color-success) !important;
}
.btn-light-secondary {
  background-color: rgba(var(--color-secondary-rgb), 0.12) !important;
  color: var(--color-secondary) !important;
}

a {
  text-decoration: none !important;
}

small.error {
  color: #de3202;
}

.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
}

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite;
  animation: dash 1.4s ease-in-out infinite;
}

.card {
  border: none;
  margin-bottom: 2rem;
  background-clip: border-box;
  border-radius: 0.428rem;
  border: none !important;
}

.dashboard {
  max-height: 100vh;
  min-height: 100vh;
  overflow: auto;

  main {
    margin: calc(90px + 1.4rem) 1.8rem 1.1rem 1.8rem;
  }
}

.navbar-container {
  padding: 0.8rem 1rem;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  position: fixed;
  right: 25px;
  z-index: 12;
  margin-top: 20px;
  transition: 0.2s all linear;
}

.dashboard .navbar-container-bg {
  position: fixed;
  right: 0px;
  margin-bottom: 20px;
  height: 40px;
  z-index: 11;
  width: calc(100%);
}

.xcoding {
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 24.5px;
}

.nav-user {
  box-shadow: none !important;
  text-decoration: none !important;
}

.avatar img {
  box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12),
    0 2px 4px 0 rgba(34, 41, 47, 0.08);
  border-radius: 50%;
  object-fit: cover;
}

.p-0 {
  padding: 0 !important;
}

.nav-user {
  color: var(--body-color) !important;
  font-weight: 600;

  & ~ .dropdown-menu.show {
    margin-top: 8px;
    border: none;
    border-radius: 0.358rem;
    box-shadow: 0 4px 25px rgba(34, 41, 47, 0.1);
    color: var(--body-color) !important;
  }
}

.dropdown-item {
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: var(--body-color) !important;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: rgba(var(--color-primary-rgb), 0.12) !important;
    color: var(--color-primary) !important;
  }
}

.dropdown-divider {
  margin: 0.5rem 0;
  border-top: 1px solid rgba(34, 41, 47, 0.08);
}

.min-100h {
  min-height: 100vh;
}

.pro-sidebar-header,
.pro-sidebar-footer {
  border-color: transparent !important;
}

thead {
  th,
  td {
    color: var(--body-color);
  }
}

thead tr,
thead th,
tr:last-child {
  border-color: transparent !important;
}

tr td:first-child,
tr th:first-child {
  padding-left: 20px;
}
tr td:last-child,
tr th:last-child {
  padding-right: 20px;
}

select.form-control {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-size: 22px 25px, 22px 25px;
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 6px;

  option {
    background-color: var(--body-bg);
  }
}

.pagination {
  li {
    width: 32px;
    height: 32px;
    display: flex;
    background-color: rgba(var(--color-primary-rgb), 0.12);

    &.previous,
    &.previous a {
      border-radius: 50% 0 0 50%;
    }
    &.next,
    &.next a {
      border-radius: 0 50% 50% 0;
    }

    a {
      width: -webkit-fill-available;
      display: flex;
      padding: 2px;
      color: var(--color-primary) !important;
      outline: none;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      font-weight: bold;

      &[aria-disabled='true'] {
        color: rgba(var(--theme-color-rgb), 0.13) !important;
      }
    }

    &.active a {
      color: #fff !important;
      background-color: var(--color-primary);
      border-radius: 50%;
    }
  }
}

.card {
  border-radius: var(--border-radius);
}

.flex-wrap-inherit {
  flex-wrap: inherit !important;
}

.btn-link {
  box-shadow: none !important;
}

.tab-content {
  margin-top: 10px;
}

.nav-tabs {
  border-bottom: 1px solid rgba(var(--theme-color-rgb), 0.2) !important;

  .nav-item {
    cursor: pointer;
    .nav-link {
      border-top-width: 2px !important;
      border-left-width: 0px !important;
      border-right-width: 0px !important;
      border-bottom-width: 2px !important;
      border-top-color: transparent !important;
      font-weight: 600;

      &.active {
        color: var(--color-primary) !important;
        border-bottom-color: var(--color-primary) !important;
      }

      &:hover {
        background-color: rgba(var(--color-primary-rgb), 0.05);
        color: var(--color-primary);
        &:not(.active) {
          border-color: rgba(var(--theme-color-rgb), 0.1);
        }
      }
      &:not(:hover) {
        background-color: transparent !important;
      }

      &:not(:hover):not(.active) {
        color: rgba(var(--theme-color-rgb), 0.6);
      }
    }
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  transition: box-shadow 0.5s;
  background-color: var(--color-primary) !important;
  box-shadow: 0 4px 18px -4px rgba(var(--color-primary-rgb), 0.65);
  &:hover {
    box-shadow: 0 4px 10px -4px rgba(var(--color-primary-rgb), 0.65);
  }
}

.modal .modal-header {
  background-color: rgba(var(--theme-color-rgb), 0.02);
  border-bottom: none;
}

.modal .modal-header,
.modal .modal-header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.7rem 0.9rem;
}

.modal-header,
.modal-header {
  border-top-left-radius: calc(0.458rem - 1px);
  border-top-right-radius: calc(0.458rem - 1px);
}

.modal .modal-header .btn-close:active,
.modal .modal-header .btn-close:focus,
.modal .modal-header .btn-close:hover {
  transform: translate(15px, -10px);
}

.modal-content {
  border: none !important;
  box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.2);
}

.btn-close {
  padding: 0.65rem !important;
  border-radius: 0.357rem;
  opacity: 1 !important;
  transform: translate(18px, -13px);
  transition: all 0.23s ease 0.1s;
  position: relative;
  color: #5e5873 !important;
  margin: -0.4rem -0.7rem -0.4rem auto;
}

.modal .modal-footer {
  padding: 0.8rem 1.4rem;
}

.modal-footer,
.modal-footer {
  border-bottom-right-radius: calc(0.358rem - 1px) !important;
  border-bottom-left-radius: calc(0.358rem - 1px) !important;
  border-top: 1px solid rgba(34, 41, 47, 0.05) !important;
}

.modal-90 {
  min-width: 90%;
}

.text-green {
  color: var(--color-success);
}

.text-red {
  color: var(--color-danger);
}

.error-select {
  border: 1px solid var(--color-danger) !important;
  border-top: 3px solid var(--color-danger) !important;
  border-radius: 5px !important;
}

.td-tooltip {
  position: relative;
  display: inline-block;
}

.td-tooltiptext {
  visibility: hidden;
  background-color: #17181c;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
}

.td-tooltip:hover ~ .td-tooltiptext {
  visibility: visible;
}

.mx--15 {
  margin: 0 -15px;
}

.card-footer {
  background-color: var(--body-bg) !important;
  display: flex;
  justify-content: flex-end !important;
  border-top: none !important;
}

.card-header {
  padding: 1.5rem;
  margin-bottom: 0;
  background-color: transparent !important;
  border-bottom: 0 solid rgba(34, 41, 47, 0.125) !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}

.text-grey {
  color: #535051;
}

.table > :not(:first-child) {
  border-top: none !important;
}

button.fc--button.fc-button.fc-button-primary {
  display: none !important;
}
button.fc-next-button.fc-button.fc-button-primary {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.fc-toolbar-chunk {
  display: flex;
  align-items: center;
}

tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid
  td {
  padding: 0 !important;
}

.error .react-select__control {
  border-color: var(--color-danger) !important;
}

.dropdown-toggle::after {
  display: inline-block !important;
  border-style: solid !important;
  border-color: #adadad !important;
  border-width: 0 2px 2px 0 !important;
  padding: 2.5px !important;
  vertical-align: middle !important;
  transition: transform 0.3s !important;
  transform: rotate(45deg) !important;
}

.sidebar-header-main {
  margin-left: 15px;
  max-height: 50px;
  overflow: hidden;
  margin-top: 10px;

  a {
    filter: drop-shadow(0px 0px 3px rgba(var(--theme-color-rgb), 0.05));
  }
}

input[type='file'] {
  background: rgba(var(--color-primary-rgb), 0.12);
  width: 100%;
  color: var(--color-primary) !important;
  border-radius: var(--border-radius) !important;
  cursor: pointer;
}
input[type='range'] {
  width: 100%;
}
::-webkit-file-upload-button {
  background: var(--color-primary) !important;
  border: none;
  font-weight: bold;
  color: #fff;
  height: 37.8px;
  padding: 0.606rem 0.75rem;
  cursor: pointer;
}
::-ms-browse {
  background: var(--color-primary);
  color: #fff;
  border: none;
  font-weight: bold;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
}

.stop {
  will-change: unset !important;
}

.editor-wrapper {
  box-sizing: content-box;

  &:hover {
    .ql-container:not(:focus) {
      border-color: rgba(var(--theme-color-rgb), 0.15) !important;
    }
  }

  .ql-toolbar {
    border-top-left-radius: 0.357rem;
    border-top-right-radius: 0.357rem;
    margin-bottom: 0;
    padding: 0.5rem;
    border-color: rgba(var(--theme-color-rgb), 0.1) !important;
    border-bottom: 0px !important;
    visibility: visible !important;
  }
  .ql-editor {
    height: 100%;
    min-height: 10rem;
  }
  .ql-container {
    border-bottom-left-radius: 0.357rem;
    border-bottom-right-radius: 0.357rem;
    border-top: none !important;
    border: 1px solid rgba(var(--theme-color-rgb), 0.1) !important;
    min-height: 10rem;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    :focus {
      border: 1px solid var(--color-primary) !important;
    }
  }
}

.btn:focus {
  box-shadow: none !important;
}

.form-control {
  &:focus {
    &::placeholder {
      transform: translate(5px);
      transition: all 0.2s ease;
    }
  }
}

.dropdown-menu {
  background-color: var(--body-bg) !important;
  border: 1.5px solid rgba(var(--theme-color-rgb), 0.05) !important;
  box-shadow: 0 5px 25px rgba(var(--theme-color-rgb), 0.01) !important;
}

.rwd-table tbody tr td {
  border-color: rgba(var(--theme-color-rgb), 0.07);
  vertical-align: middle;
}

.rwd-table tfoot {
  th,
  td {
    border-color: rgba(var(--theme-color-rgb), 0.07) !important;
  }
}

.bl-1 {
  border-left: 0.5px solid rgba(var(--theme-color-rgb), 0.1);
}
.bl-2 {
  border-left: 1px solid rgba(var(--theme-color-rgb), 0.1);
}
.br-1 {
  border-right: 0.5px solid rgba(var(--theme-color-rgb), 0.1);
}
.br-2 {
  border-right: 1px solid rgba(var(--theme-color-rgb), 0.1);
}
.bb-1 {
  border-bottom: 0.5px solid rgba(var(--theme-color-rgb), 0.1);
}
.bb-2 {
  border-bottom: 1px solid rgba(var(--theme-color-rgb), 0.1);
}

.list-group-item {
  background-color: rgba(var(--theme-color-rgb), 0.03) !important;
  color: var(--body-color) !important;
}

.btn-muted {
  color: rgba(var(--theme-color-rgb), 0.6) !important;
}
.object-center {
  -o-object-position: center;
  object-position: center;
}

.object-fit {
  object-fit: contain;
}
.object-cover {
  object-fit: cover;
}

[class*='btn-light'] {
  font-weight: 500 !important;
}

.btn-link:not(.nav-user) {
  color: var(--color-primary) !important;
}

code {
  background: #d6338421;
  border-radius: var(--border-radius);
  padding: 2px 6px;
}

.swal2-popup {
  background: var(--body-bg) !important;
}

.no-padding {
  padding: 0px !important;
}

.image-preview-group {
  width: 100%;
  margin-bottom: 0.3rem;
  display: flex;
  justify-content: center;

  div:not(.upload-options) {
    width: 100%;
    justify-content: center;
    display: flex;
    position: relative;
  }

  img {
    height: 250px;
  }
  img.radius {
    border-radius: 100%;
  }
}

.upload-options {
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 0;
  // background-color: var(--body-bg);
  border-radius: var(--border-radius);

  button {
    border: none !important;
    width: 100%;
    padding: 0.5857rem 0;
    margin-bottom: 0 !important;
    font-weight: 600;
    cursor: pointer;
    background-color: rgba(var(--color-danger-rgb), 0.32);
    color: #fff !important;
    backdrop-filter: saturate(180%) blur(5px);
    font-size: 1rem !important;
  }
  label {
    border: none !important;
    width: 100%;
    padding: 0.5857rem 0;
    margin-bottom: 0 !important;
    font-weight: 600;
    cursor: pointer;
    font-size: 1rem !important;
    backdrop-filter: saturate(180%) blur(5px);
    background-color: rgba(var(--color-primary-rgb), 0.32);
    color: #fff !important;
  }

  & input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

.image-preview {
  position: relative;
  overflow: hidden;
  &.preview {
    background-color: #ededed;
  }
}

.img-login {
  width: 50%;
  height: 50%;
  // max-width: 35vw;
}

.nav-tabs {
  overflow-x: auto !important;
  overflow-y: hidden !important;
  display: flex;
  flex-wrap: nowrap !important;
  white-space: nowrap !important;
  width: 100%;
}

.hr-vertical {
  height: 23px;
  margin-top: 5px;
  width: 2px;
  background-color: rgba(var(--theme-color-rgb), 0.3);
}
.hr {
  height: 1px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgba(var(--theme-color-rgb), 0.1);
}

.min-h-250 {
  min-height: 250px;
}

.card-product {
  display: grid;
  padding: 10px 10px 0px;
}
.align-self-end {
  align-self: flex-end;
}

.d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.custom.input-group {
  flex-wrap: nowrap !important;

  .input-group-text {
    padding: 0 !important;
    min-width: 30%;
    border-width: 0 1px 0 0 !important;
    border-color: rgba(var(--theme-color-rgb), 0.1) !important;
    background-color: transparent;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;

    background: transparent;
    border: none;
    padding: 0.375rem 1.35rem 0.375rem 0.75rem;
    width: 100%;

    text-overflow: ellipsis;
    overflow: hidden;

    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
    background-size: 22px 25px, 22px 25px;
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 4px;
  }

  div {
    padding: 0.375rem 0.75rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  input {
    border: none;
    padding: 0.375rem 0.75rem;
    width: 100%;
    background: transparent;
  }
}

.overflow-visible {
  overflow: visible;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.width-min {
  width: min-content;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}

.line-through {
  text-decoration: line-through;
}

.radius-4 {
  border-radius: 4px;
}

.shadow-low {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
}

.dot-left {
  display: flex;
  font-weight: bold;

  p {
    margin: 1rem 0 1rem 1rem;
  }

  &.active {
    color: var(--color-primary);
    &::before {
      background: var(--color-primary);
    }
  }
  &.active {
    color: var(--color-primary);
  }
  &::before {
    content: '';
    display: flex;
    width: 3px;
    background: #ccc;
  }
}

.touchable {
  border: 0px;
  outline: none !important;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
}

.btn-img-placeholder {
  width: 100%;
  height: 300px;
  background-color: rgba(var(--theme-color-rgb), 0.05);
  display: flex;
  color: rgba(var(--theme-color-rgb), 0.8);
  justify-content: center;
  align-items: center;
}

.decoration-none {
  text-decoration: none !important;
}

.no-radius {
  border-radius: 0px !important;
}

.txt-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.bouncing-loader > div {
  width: 12px;
  height: 12px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: rgba(var(--theme-color-rgb), 1);
  opacity: 0.2;
  animation: bouncing-loader 0.6s infinite alternate;
}

.bg-theme-light {
  background-color: rgba(var(--theme-color-rgb), 0.03);
}

.bordered-danger {
  border: 1px dashed rgba(var(--color-danger-rgb), 0.3);
  border-radius: 6px;
}
.bordered-success {
  border: 1px dashed rgba(var(--color-success-rgb), 0.3);
  border-radius: 6px;
}
.bordered-primary {
  border: 1px dashed rgba(var(--color-primary-rgb), 0.3);
  border-radius: 6px;
}

.input-group.custom.input-height input {
  height: 2.714rem;
}

.btn-none {
  outline: none;
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.border-1 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.z-index-1 {
  z-index: 1;
}
.chat-container {
  min-height: 50vh;
}

.color-white {
  color: #fff;
}
.border-none {
  border: none !important;
}
.border-default {
  border: 1px solid rgba(var(--theme-color-rgb), 0.18);
  border-radius: var(--border-radius);
}

.upper {
  text-transform: uppercase;
}

.swal2-file {
  width: 75% !important;
}

.p-small {
  line-height: initial;
  font-size: 0.875em;
}

.table-responsive:has(> .min-height) {
  min-height: 200px;
}

.no-wrap {
  white-space: nowrap;
  width: 100%;
  overflow: hidden; /* "overflow" value must be different from "visible" */
  text-overflow: ellipsis;
}

.scale-hover {
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);

  // filter: blur(1px) saturate(180%);
  &:hover {
    transform: scale(1.05);
  }
}

.scale-hover-low {
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);

  // filter: blur(1px) saturate(180%);
  &:hover {
    transform: scale(1.01);
  }
}

.color-black {
  color: #000;
}

.m-sub-header,
.m-header {
  line-height: 1.08349;
  font-weight: 600;
  letter-spacing: -0.003em;
  display: inline;
}
.m-sub-header {
  font-size: 25px !important;
}

.p-15px {
  padding: 15px;
}

.horizontal-list {
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .list-item:last-of-type {
    margin-right: 10px;
  }

  .list-item {
    overflow: hidden;
    margin-left: 10px;
    border-radius: 10px;
    transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
    .position-relative:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
      background: #000;
      opacity: 0.5;
    }
    &:hover {
      .position-relative:before {
        opacity: 0.1;
      }
      transform: translateY(-6px);
    }
  }
}

.position-relative {
  position: relative;
  width: 100%;
  height: 100%;
}

.list-item h3.position-absolute {
  z-index: 2;
  margin: 20px;
  color: #000 !important;
  font-weight: 600;
  text-shadow: 2px 0 #ffffff40, -2px 0 #ffffff40, 0 2px #ffffff40,
    0 -2px #ffffff40, 1px 1px #ffffff40, -1px -1px #ffffff40, 1px -1px #ffffff40,
    -1px 1px #ffffff40;
  //  text-shadow: 2px 0 #00000040, -2px 0 #00000040, 0 2px #00000040, 0 -2px #00000040,
  //              1px 1px #00000040, -1px -1px #00000040, 1px -1px #00000040, -1px 1px #00000040;
}
.position-absolute {
  position: absolute;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgba(var(--theme-color-rgb), 0.125);
}

.separator:not(:empty)::before {
  margin-right: 1em;
}

.separator:not(:empty)::after {
  margin-left: 1em;
}

header {
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  backdrop-filter: saturate(180%) blur(10px);
  background-color: rgba(0, 0, 0, 0.55);
  margin: auto;
  position: fixed;
  padding: 10px;
  top: 0;
  width: 100%;
  z-index: 100;

  img {
    -webkit-filter: drop-shadow(0 0 1px hsla(0, 0%, 43%, 0.658))
      drop-shadow(0 0 1px hsla(0, 0%, 43%, 0.432));
    filter: drop-shadow(0 0 1px rgba(110, 110, 110, 0.658))
      drop-shadow(0 0 1px rgba(110, 110, 110, 0.432));
  }
}

.card-text-login {
  color: #999999;
}

.home thead tr th {
  border-bottom: 2px solid var(--background-secondary) !important;
}

.home tbody tr  {
  border-bottom: 1px solid var(--background-secondary) !important;
}

.bg-dark3 {
  background-color: #1c1b20;
}
.bg-dark1 {
  background-color: #121212;
}
.bg-dark2 {
  background-color: var(--background-secondary);
}

.img-banner {
  max-width: 1920px;
  width: 100%;
  object-fit: contain;
}

.grid {
  display: grid;
}

.items-center {
  align-items: center;
}

.tracking-normal {
  letter-spacing: 2px;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hidden {
  display: none;
}

@media (min-width: 960px) {
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:text-7xl {
    font-size: 4.5rem !important;
    line-height: 1 !important;
  }

  .lg\:py-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .lg\:pr-12 {
    padding-right: 3rem;
  }

  .lg\:pr-20 {
    padding-right: 5rem !important;
  }

  .lg\:text-left {
    text-align: left !important;
  }

  .lg\:text-6xl {
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }

  .lg\:flex {
    display: flex !important;
  }

  .lg\:object-right {
    object-position: right !important;
  }

  .lg\:pt-0 {
    padding-top: 0px !important;
  }

  .lg\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .lg\:row-auto {
    grid-row: auto !important;
  }

  .lg\:w-1\/2 {
    width: 50% !important;
  }
}

@media (min-width: 1140px) {
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

@media (min-width: 720px) {
  .md\:gap-8 {
    gap: 2rem !important;
  }

  .md\:absolute {
    position: absolute !important;
  }

  .md\:flex-row {
    flex-direction: row !important;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:max-h-\[75vh\] {
    max-height: 75vh !important;
  }

  .md\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .md\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .md\:px-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .md\:w-7\/12 {
    width: 58.333333% !important;
  }

  .md\:w-5\/12 {
    width: 41.666667% !important;
  }

  .md\:h-auto {
    height: auto !important;
  }
  .md\:flex {
    display: flex !important;
  }

  .md\:hidden {
    display: none !important;
  }
}

.h-96 {
  height: 24rem !important;
}

.z-20 {
  z-index: 20;
}

.p-6 {
  padding: 1.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.gap-8 {
  gap: 2rem;
}

.max-h-\[50vh\] {
  max-height: 50vh;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.justify-center {
  justify-content: center;
}

.items-end {
  align-items: flex-end;
}

.flex-col {
  flex-direction: column;
}

.flex {
  display: flex;
}

.max-w-\[28rem\] {
  max-width: 31rem;
}

.h-\[25rem\] {
  height: 29rem;
}

.relative {
  position: relative;
}

.bg-clip-border {
  background-clip: border-box;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.max-w-md {
  max-width: 28rem;
}
.shadow-card-img {
  box-shadow: 8px 2px 10px rgb(255 255 255 / 8%);
}

.ml-auto {
  margin-left: auto;
}

.leading-tight {
  line-height: 1.25;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-lg {
  font-size: 1rem;
  line-height: 1.55rem;
}

.font-normal {
  font-weight: 400;
}

.leading-relaxed {
  line-height: 1.625;
}

.font-sans {
  font-family: Roboto, sans-serif;
}

.py-28 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-8 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pt-8 {
  padding-top: 2.5rem;
}
.pb-8 {
  padding-bottom: 2.5rem;
}

.px-8 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.mb-6 {
  margin-bottom: 2rem;
}
.mb-8 {
  margin-bottom: 2.5rem;
}

.mt-8 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.row-start-2 {
  grid-row-start: 2;
}

.block {
  display: block;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(
    to right,
    #302e34 10%,
    #302e34c9 18%,
    #302e34 33%
  );
  background-size: 800px 800px;
  background-repeat: repeat;
  height: 100%;
  position: relative;
}

.place-items-center {
  place-items: center;
}

.h-full {
  height: 100% !important;
}

.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw;
}

.mt-16 {
  margin-top: 4rem;
}
.mb-16 {
  margin-bottom: 4rem;
}
.mb-24 {
  margin-bottom: 6rem;
}
.mt-24 {
  margin-top: 6rem;
}

.home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img:not(.img-main),
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }

  img:not(.img-main),
  video {
    max-width: 100%;
    height: auto;
  }
}

.img-main {
  object-fit: cover;
  height: calc(100vh - 7rem - 55px);
}
.pt-70px {
  padding-top: 70px;
}

.text-3xl {
  font-size: 1.75rem;
  line-height: 1.75rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.leading-\[1\.3\] {
  line-height: 1.3;
}

.font-semibold {
  font-weight: 600;
}

.bg-center {
  background-position: center;
}

.inset-0 {
  inset: 0;
}

.bg-cover {
  background-size: cover;
}

.leading-snug {
  line-height: 1.375;
}

.bg-transparent {
  background-color: transparent;
}

.bg-gradient-to-t {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0.3) 35%,
    rgba(0, 0, 0, 0.55) 60%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  transition: all 0.4s;

  &:hover {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.2) 35%,
      rgba(0, 0, 0, 0.5) 70%
    );
  }
}

.bg-gradient-to {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0.5) 60%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.rounded-none {
  border-radius: 0;
}

.p-6 {
  padding: 1.5rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

footer {
  .container {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  // img {
  //   -webkit-filter: drop-shadow(0 0 1px hsla(0, 0%, 43%, 0.658))
  //     drop-shadow(0 0 1px hsla(0, 0%, 43%, 0.432));
  //   filter: drop-shadow(0 0 1px rgba(110, 110, 110, 0.658))
  //     drop-shadow(0 0 1px rgba(110, 110, 110, 0.432));
  // }
}

.gray-1 {
  color: #85858b;
}

.svg-shadow {
  filter: drop-shadow(2px 3px 2px rgb(0 0 0 / 0.4));
}

.float {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: pulse 1.5s ease-out infinite;
  animation: pulse 1.5s ease-out infinite;
}

.tag {
  background-color: #f0f0f0;
  margin: 2px;
  border-radius: 15px;
  padding: 4px;
  color: #000000;
  font-size: 11px;
}

.h-64 {
  height: 16rem;
}

.h-70 {
  height: 20rem;
}

.\!mb-6 {
  margin-bottom: 1.5rem !important;
}

.\!m-0 {
  margin: 0 !important;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.navmenu {
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  backdrop-filter: saturate(180%) blur(10px);
  position: fixed;
  top: 72px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.85);
  border-top: 1px solid #302e34;

  div {
    margin-bottom: 1.5rem;
    font-size: 1.2rem !important;
    text-align: center;
  }
}

html:has(*.block-scroll) {
  overflow: hidden !important;
}

footer .grid {
  grid-template-columns: 100%;

  .lg\:flex {
    grid-row: 1;
  }
}

.scale-down {
  object-fit: scale-down;
}

.card-cliente {
  padding: 0.5rem;
  border-radius: 0.38rem;
  background-color: #fff;
}

.pre-wrap {
  font-family: 'Roboto', -apple-system, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
  font-size: 1.15rem;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-spacing: 2px;
}

.btn-round {
  border-radius: 98px !important;
}

.btn-primary-outline {
  color: #2b81ff;
  border-color: #2b81ff !important;
  background-color: transparent;

  &:hover {
    color: #fff;
    background-color: #2b81ff;
  }
}

.btn-primary-light {
  background-color: rgb(229 230 246);
  color: #2935b5;
}


.product-image__img {
  display: block;
  height: 100%;
  object-fit: scale-down;
  position: absolute;
  width: 100%;
}

.hr-bottom {
  border-bottom: 1px solid rgba(255,255,255,0.4)
}

.align-items-center {
  align-items: center !important;
}


.btn-none.color-white.dropdown-toggle::after {
  margin-top: -4px;
}
